@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600&display=swap);
.logo, .navbar, section, footer {
  -webkit-animation: fadeInNormal ease 2s;
          animation: fadeInNormal ease 2s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

footer {
  -webkit-animation: fadeInFast ease-out 3s;
          animation: fadeInFast ease-out 3s;
}

@-webkit-keyframes fadeInNormal {
  0%, 50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInNormal {
  0%, 50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeInFast {
  0%, 80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInFast {
  0%, 80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.logo {
  color: rgba(241, 241, 241, 0.75);
  cursor: default;
  font-size: 1.2em;
  font-weight: 200;
  margin: 0;
}
.logo.large {
  color: rgba(241, 241, 241, 0.5);
  font-size: 12vmin;
  letter-spacing: -5px;
}
@media (max-width: 575.98px) {
  .logo.large {
    font-size: 4rem;
    letter-spacing: -3.5px;
  }
}
.logo b {
  font-weight: 400;
}
footer {
  background-color: #1e222a;
  position: fixed;
  bottom: 0;
  display: grid;
  align-items: flex-start;
  justify-content: center;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  height: 200px;
  z-index: -1;
}
footer > article {
  margin: 60px 20px;
}
footer > article:first-child {
  font-size: 1.3em;
  font-weight: 400;
}
footer > article ul {
  padding: 0;
  margin: 0;
}
footer > article ul li {
  list-style: none;
}
footer a {
  color: #ddd;
}
footer small {
  font-size: 14px;
  opacity: 0.6;
}
.loader {
  width: 53px;
  height: 12px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -26.5px;
  margin-top: -6px;
  -webkit-animation: fadeOut ease 1s;
          animation: fadeOut ease 1s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  75%, 100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  75%, 100% {
    opacity: 0;
  }
}
.navbar {
  background-color: rgba(53, 56, 64, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  z-index: 10;
  transition: background-color ease 0.25s;
}
.navbar .container {
  width: 960px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.navbar .container a {
  color: rgba(241, 241, 241, 0.5);
  margin-left: auto;
}
.wrapper {
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}

.row {
  box-sizing: border-box;
  display: -moz-flex;
  display: flex;
  -moz-flex: 0 1 auto;
  flex: 0 1 auto;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.row.reverse {
  -moz-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.col.reverse {
  -moz-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.col-xs {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: auto;
}

.col-xs-1 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-xs-2 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-xs-3 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-xs-5 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-xs-6 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-xs-8 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-xs-9 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-xs-11 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-xs-12 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-1 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 8.3333333333%;
}

.col-xs-offset-2 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 16.6666666667%;
}

.col-xs-offset-3 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 25%;
}

.col-xs-offset-4 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 33.3333333333%;
}

.col-xs-offset-5 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 41.6666666667%;
}

.col-xs-offset-6 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 50%;
}

.col-xs-offset-7 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 58.3333333333%;
}

.col-xs-offset-8 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 66.6666666667%;
}

.col-xs-offset-9 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 75%;
}

.col-xs-offset-10 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 83.3333333333%;
}

.col-xs-offset-11 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 91.6666666667%;
}

.col-xs-offset-12 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 100%;
}

.col-xs {
  -moz-flex-grow: 1;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.start-xs {
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  -moz-justify-content: center;
  justify-content: center;
  text-align: center;
}

.end-xs {
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  -moz-align-items: flex-start;
  align-items: flex-start;
}

.middle-xs {
  -moz-align-items: center;
  align-items: center;
}

.bottom-xs {
  -moz-align-items: flex-end;
  align-items: flex-end;
}

.around-xs {
  -moz-justify-content: space-around;
  justify-content: space-around;
}

.between-xs {
  -moz-justify-content: space-between;
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem;
  }

  .col-sm {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto;
  }

  .col-sm-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.3333333333%;
  }

  .col-sm-offset-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.6666666667%;
  }

  .col-sm-offset-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.3333333333%;
  }

  .col-sm-offset-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.6666666667%;
  }

  .col-sm-offset-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.3333333333%;
  }

  .col-sm-offset-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.6666666667%;
  }

  .col-sm-offset-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.3333333333%;
  }

  .col-sm-offset-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.6666666667%;
  }

  .col-sm-offset-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%;
  }

  .col-sm {
    -moz-flex-grow: 1;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .start-sm {
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    -moz-justify-content: center;
    justify-content: center;
    text-align: center;
  }

  .end-sm {
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    -moz-align-items: flex-start;
    align-items: flex-start;
  }

  .middle-sm {
    -moz-align-items: center;
    align-items: center;
  }

  .bottom-sm {
    -moz-align-items: flex-end;
    align-items: flex-end;
  }

  .around-sm {
    -moz-justify-content: space-around;
    justify-content: space-around;
  }

  .between-sm {
    -moz-justify-content: space-between;
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}
@media only screen and (min-width: 62em) {
  .container {
    width: 61rem;
  }

  .col-md {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto;
  }

  .col-md-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.3333333333%;
  }

  .col-md-offset-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.6666666667%;
  }

  .col-md-offset-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }

  .col-md-offset-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.3333333333%;
  }

  .col-md-offset-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.6666666667%;
  }

  .col-md-offset-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }

  .col-md-offset-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.3333333333%;
  }

  .col-md-offset-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.6666666667%;
  }

  .col-md-offset-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }

  .col-md-offset-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.3333333333%;
  }

  .col-md-offset-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.6666666667%;
  }

  .col-md-offset-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%;
  }

  .col-md {
    -moz-flex-grow: 1;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .start-md {
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    -moz-justify-content: center;
    justify-content: center;
    text-align: center;
  }

  .end-md {
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    -moz-align-items: flex-start;
    align-items: flex-start;
  }

  .middle-md {
    -moz-align-items: center;
    align-items: center;
  }

  .bottom-md {
    -moz-align-items: flex-end;
    align-items: flex-end;
  }

  .around-md {
    -moz-justify-content: space-around;
    justify-content: space-around;
  }

  .between-md {
    -moz-justify-content: space-between;
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}
@media only screen and (min-width: 75em) {
  .container {
    width: 71rem;
  }

  .col-lg {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto;
  }

  .col-lg-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.3333333333%;
  }

  .col-lg-offset-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.6666666667%;
  }

  .col-lg-offset-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.3333333333%;
  }

  .col-lg-offset-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.6666666667%;
  }

  .col-lg-offset-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.3333333333%;
  }

  .col-lg-offset-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.6666666667%;
  }

  .col-lg-offset-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.3333333333%;
  }

  .col-lg-offset-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.6666666667%;
  }

  .col-lg-offset-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%;
  }

  .col-lg {
    -moz-flex-grow: 1;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .start-lg {
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    -moz-justify-content: center;
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    -moz-align-items: flex-start;
    align-items: flex-start;
  }

  .middle-lg {
    -moz-align-items: center;
    align-items: center;
  }

  .bottom-lg {
    -moz-align-items: flex-end;
    align-items: flex-end;
  }

  .around-lg {
    -moz-justify-content: space-around;
    justify-content: space-around;
  }

  .between-lg {
    -moz-justify-content: space-between;
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}
section {
  background-color: #3e4551;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: 0 0.5rem;
}
section.home {
  background: #3e4551 url(/images/fog-background_compressed.png) no-repeat center center;
  background-size: auto;
}
section.home > div {
  cursor: default;
  font-size: 1.4em;
  margin: 0;
  margin-top: 20px;
}
section.home > div span {
  color: #e6d2bc;
}
section.home > div small {
  font-size: 0.5em;
  text-transform: uppercase;
  letter-spacing: 3px;
}
section.home > div:nth-of-type(2) {
  opacity: 0.85;
  font-size: 1.25em;
  margin-top: 5px;
}
section.home > div:nth-of-type(3) {
  opacity: 0.8;
  font-size: 1.15em;
  margin-top: 8px;
}
section.whoami {
  background-color: #30353e;
  position: relative;
  z-index: 1;
  align-items: center;
}
section.whoami img {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  object-fit: cover;
}
section.whoami h5 {
  font-size: 1em;
  font-weight: 500;
  opacity: 0.6;
  margin-top: 10px;
  margin-bottom: 10px;
}
section.whoami p {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.3;
  opacity: 0.8;
  margin-top: 0;
}
section.whoami .row > div {
  text-align: center;
  padding: 0;
  margin-bottom: 40px;
}
section.whoami .row > div:nth-child(1) > img, section.whoami .row > div:nth-child(3) > img {
  object-position: top;
}
section.whoami .row > div:nth-child(6) > img {
  object-position: top;
}
section:last-of-type {
  margin-bottom: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.275);
}
html {
  scroll-behavior: smooth;
}

body {
  background-color: #1e222a;
  color: rgba(241, 241, 241, 0.75);
  margin: 0;
}

a, a:visited, a:link, a:active {
  text-decoration: none;
}

.wrapper {
  height: 100vh;
}

:selection {
  background-color: #3e4551;
}
body {
  font-family: Helvetica Neue, Source Sans Pro, sans-serif;
  font-size: 16px;
  font-weight: 200;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.075);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

h1 {
  font-weight: 200;
  font-size: 2.5em;
  margin-bottom: 2em;
  opacity: 0.7;
}
