@import global

html
  scroll-behavior: smooth

body
  background-color: $footer-background
  color: fade-out(#f1f1f1, 0.25)
  margin: 0

a
  &,
  &:visited,
  &:link,
  &:active
    text-decoration: none

.wrapper
  height: 100vh

:selection
  background-color: $dark-gray
