@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600&display=swap')

body
  font-family: Helvetica Neue, Source Sans Pro, sans-serif
  font-size: 16px
  font-weight: 200
  text-shadow: 1px 1px 4px rgba(0,0,0,0.075)

h1, h2, h3, h4, h5, h6
  font-weight: 400

h1
  font-weight: 200
  font-size: 2.5em
  margin-bottom: 2em
  opacity: 0.7
