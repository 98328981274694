@import '../global/_variables'

.navbar
  background-color: $navbar-background
  position: fixed
  top: 0
  left: 0
  right: 0
  width: 100%
  height: 52px
  display: flex
  justify-content: center
  z-index: 10
  transition: background-color ease 0.25s

  .container
    width: 960px
    padding: 0 20px
    display: flex
    align-items: center
    a
      color: fade-out(#f1f1f1, 0.5)
      margin-left: auto
