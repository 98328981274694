.logo, .navbar, section, footer
  animation: fadeInNormal ease 2s
  animation-iteration-count: 1
  animation-fill-mode: forwards

footer
  animation: fadeInFast ease-out 3s

@keyframes fadeInNormal
  0%,
  50%
    opacity: 0

  100%
    opacity: 1

@keyframes fadeInFast
  0%,
  80%
    opacity: 0

  100%
    opacity: 1
