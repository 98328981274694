.loader
  width: 53px
  height: 12px
  position: absolute
  left: 50%
  top: 50%
  margin-left: -26.5px
  margin-top: -6px
  animation: fadeOut ease 1s
  animation-iteration-count: 1
  animation-fill-mode: forwards

@keyframes fadeOut
  0%
    opacity: 1

  75%,
  100%
    opacity: 0
